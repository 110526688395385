import React, { Component } from 'react';
import {Alert, Badge, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {GestAdmin, DeleteAdmin, Loading} from "custom";

import { AdminAPI, UserAPI } from "api";


class AdministratorTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            admins: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getAdministrators();
    }

    getAdministrators() {
        AdminAPI.list().then((admins) => {
            this.setState({loading: false, error: false, admins: admins});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    resendEmailSignup(id) {
        const self = this;
        global.SweetAlert.fire({
            title: 'Vuoi reinviare la mail di registrazione?',
            text: "Stai per reinviare una mail all'utente con il link per la registrazione, vuoi procedere?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    AdminAPI.resendSignup(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile inviare nuovamente il codice di registrazione. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Email registrazione inviata!',
                    text: 'È stata inviata la mail di registrazione all\'utente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.getAdministrators();
            }
        })
    }


    render() {
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista amministratori.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{margin: 10}}>
                    <GestAdmin onResolve={this.getAdministrators.bind(this)}/>
                </div>
                {(this.state.loading) ? <Loading /> :
                <Grid
                    data={this.state.admins}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'name',
                            name: 'Nominativo',
                            formatter: (cell, row) => _(<span className="text-nowrap">{cell}</span>)
                        },
                        {
                            id: 'email',
                            name: 'Email',
                            formatter: (cell, row) => _(<span className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></span>)
                        },
                        {
                            id: 'cell',
                            name: 'Telefono',
                            sort: false,
                            formatter: (cell, row) => _(<span className="text-nowrap"><a href={"tel:" + cell}>{cell}</a></span>)
                        },
                        {
                            id: 'isRegistered',
                            hidden: true
                        },
                        {
                            id: 'active',
                            name: 'Stato',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(cell) ? <Badge color="success" >Attivo</Badge> : ((row.cells[4].data) ? <Badge color="danger" >Non Attivo</Badge> : <Badge color="warning" className="cursor-pointer" onClick={() => this.resendEmailSignup(row.cells[0].data)}>In attesa registrazione</Badge>)}
                            </span>)
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                <GestAdmin preset={this.state.admins[this.state.admins.findIndex((a) => a.id === row.cells[0].data)]} onResolve={this.getAdministrators.bind(this)} />
                                <DeleteAdmin user={{id: row.cells[0].data}} onResolve={this.getAdministrators.bind(this)} />
                            </ul>)
                        }
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Amministratori',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun amministratore trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        );
    }
}

export default withRouter(AdministratorTable);
