let list = function(role) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.admin, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('admins list', responseJson);
                if (responseJson.status === "LIST_ADMINS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(user) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.admin, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: user.name,
                email: user.email,
                cell: user.cell
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('admin add', responseJson);
                if (responseJson.status === "ADMIN_CREATED")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(form) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.admin, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: form.id,
                active: form.active,
                name: form.name,
                email: form.email,
                cell: form.cell
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('admin edit', responseJson);
                if (responseJson.status === "ADMIN_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.admin, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('admin delete', responseJson);
                if (responseJson.status === "ADMIN_DELETED")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let completeSignup = function (user, token) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.signup + '?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: user.name,
                email: user.email,
                cell: user.cell,
                password: user.password
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : console.log(response))
            .then((responseJson) => {
                if (responseJson.status === "USER_SIGNUP_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                window.location.assign('/server/error');
            });
    });
};


let resendSignup = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.resendSignup, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('user resendSignup', responseJson);
                if (responseJson.status === "EMAIL_SENT")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const AdminAPI = {
    list: list,
    add: add,
    edit: edit,
    remove: remove,
    resendSignup: resendSignup,
    completeSignup: completeSignup
};

module.exports = AdminAPI;
