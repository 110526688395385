const countriesJSON = require("../assets/countries.json");
const {Badge} = require("reactstrap");
const moment = require("moment/moment");
const React = require("react");

let isValidEmail = function (text) {
    let reg = /^[\w-]+([.-]?[\w-]+)*@[\w-]+(\.\w{2,3})+$/;

    //   let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(text);
};

let isValidPassword = function (text) {
    const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    return reg.test(text);
};

let isValidPhone = function (text) {
    let reg = /^\+(?:[0-9]●?){8,12}[0-9]$/im;
    // let reg = /^[0-9]{5,10}$/;
    return reg.test(text);
};

let isValidCell = function (text) {
    let reg = /^\+[1-9]{1}[0-9]{3,14}$/im;
    return reg.test(text);
};

let isValidFiscalCode = function (text) {
    let reg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
    return reg.test(text);
};

let isValidIban = function (text) {
    let reg = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/gm;
    return reg.test(text);
};


let isPiva = function (text) {
    // let reg = /^[0-9]{15}$/;
   // return reg.test(text);
    return true;
};

let isValidSwiftCode = function (text) {
    let reg = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
    return reg.test(text);
};

let isPercentage = function (number) {
    if (isNaN(number))
        return false;

    return (number >= 0 && number <= 100);
};


let getValueMoneyLabel = function (number) {
    if (number === null) {
        return '0,00 €';
    }

    if (typeof number !== 'number') {
        return '0,00 €';
    }
    return String(number.toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') +  ' €';
};

let isValidUrl = function (text) {
    let reg = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
    return reg.test(text);
};

let isUserLoggedIn = function () {
    return (localStorage.getItem('hash') !== null);
}

let getUserData = function () {
    return (JSON.parse(sessionStorage.getItem('userData')));
}

let checkConnection = function () {
    return new Promise(function (resolve, reject) {
        let condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            fetch(global.route.test, {
                method: 'GET'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 'TEST_OK')
                        resolve();
                    else
                        reject();
                })
                .catch((e) => {
                    reject();
                });

        } else {
            reject();
        }
    });
}


let getRoleLabel = function (role) {
    switch(role) {
        case "ADMIN":
            return "AMMINISTRATORE";
        case "TEACHER":
            return "DOCENTE";
        case "CONSULENT":
            return "CONSULENTE";
        default:
            return "--";
    }
}


let getAlloweedPermissions = function () {
    return [
        {key: "DASHBOARD", label: "DASHBOARD"},
        {key: "ADMIN", label: "AMMINISTRATORI"},
        {key: "CONSULENT", label: "CONSULENTI"},
        {key: "TEACHER", label: "DOCENTI"},
        {key: "CLIENT", label: "CLIENTI"},
        {key: "SELL", label: "VENDITE"},
        {key: "PAYMENT", label: "PAGAMENTI"},
        {key: "LEAD", label: "LEADS"},
        {key: "COURSE", label: "CORSI"},
        {key: "CLASSROOM", label: "CLASSI"},
        {key: "TICKET", label: "TICKET"}
    ]
}

let getAlloweedCustomStatus = function (actualStatus) {

    let statusArr = [
        {value: "WORKING", label: "IN LAVORAZIONE", class: "status-working"},
        {value: "APPOINTMENT", label: "DA RICHIAMARE", class: "status-appointment"},
        {value: "REMARKETING", label: "REMARKETING", class: "status-remarketing"},
        {value: "UNREPLY", label: "SENZA RISPOSTA", class: "status-unreply"}
    ]

    if (actualStatus === "ENDED")
        statusArr.push({value: "ENDED", label: "SCARTATO", class: "status-closed"});

    if (actualStatus === "TRIAL")
        statusArr.push({value: "TRIAL", label: "IN PROVA", class: "status-trial"});

    return statusArr
}



let printListCountries = function () {
    const countriesJSON = require("../assets/countries.json");
    let result = [];

    for (let i = 0; i < countriesJSON.length; i++) {
        result.push({
            value: countriesJSON[i].alpha3,
            label: countriesJSON[i].name,
            img: countriesJSON[i].file_url
        })
    }

    return result;
}

let isCountry = function (country) {
    const countriesJSON = require("../assets/countries.json");
    for (let i = 0; i < countriesJSON.length; i++) {
        if (countriesJSON[i].alpha3 === country)
            return true;
    }
    return false;
}

let getFlagByCountry = function (country) {
    const countriesJSON = require("../assets/countries.json");
    for (let i = 0; i < countriesJSON.length; i++) {
        if (countriesJSON[i].alpha3 === country)
            return countriesJSON[i].file_url;
    }
    return false;
}

let getPrefixByCountry = function (country) {
    const countriesJSON = require("../assets/countryPrefix.json");
    for (let i = 0; i < countriesJSON.length; i++) {
        if (countriesJSON[i].code === country)
            return countriesJSON[i].dial_code;
    }
    return false;
}

let getSlotTrial = function () {
    return [
        {value: "8:00 - 9:00", label: "8:00 - 9:00"},
        {value: "9:00 - 10:00", label: "9:00 - 10:00"},
        {value: "10:00 - 11:00", label: "10:00 - 11:00"},
        {value: "11:00 - 12:00", label: "11:00 - 12:00"},
        {value: "12:00 - 13:00", label: "12:00 - 13:00"},
        {value: "13:00 - 14:00", label: "13:00 - 14:00"},
        {value: "14:00 - 15:00", label: "14:00 - 15:00"},
        {value: "15:00 - 16:00", label: "15:00 - 16:00"},
        {value: "16:00 - 17:00", label: "16:00 - 17:00"},
        {value: "17:00 - 18:00", label: "17:00 - 18:00"},
        {value: "18:00 - 19:00", label: "18:00 - 19:00"},
        {value: "19:00 - 20:00", label: "19:00 - 20:00"},
        {value: "20:00 - 21:00", label: "20:00 - 21:00"}
    ]
}



const Utils = {
    isValidEmail: isValidEmail,
    isValidPassword: isValidPassword,
    isValidPhone: isValidPhone,
    isValidCell: isValidCell,
    isValidFiscalCode: isValidFiscalCode,
    isValidIban: isValidIban,
    isValidSwiftCode: isValidSwiftCode,
    isPiva: isPiva,
    isValidUrl: isValidUrl,
    isPercentage: isPercentage,
    getValueMoneyLabel: getValueMoneyLabel,
    isUserLoggedIn: isUserLoggedIn,
    checkConnection: checkConnection,
    getRoleLabel: getRoleLabel,
    getUserData: getUserData,
    getAlloweedPermissions: getAlloweedPermissions,
    printListCountries: printListCountries,
    isCountry: isCountry,
    getPrefixByCountry: getPrefixByCountry,
    getFlagByCountry: getFlagByCountry,
    getSlotTrial: getSlotTrial,
    getAlloweedCustomStatus: getAlloweedCustomStatus
};

module.exports = Utils;
