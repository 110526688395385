import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, FormText } from 'reactstrap';

import { Link } from "react-router-dom";
import { AuthAPI } from "api";

import {Utils, Loading} from "custom";

const moment = require("moment");

class RedirectSignupForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formOtp: ["", "", "", ""],
            error: false,
            loading: false,
            lastSend: Number(moment().format("X"))
        };
    }

    onChangeText(i, value) {

        const formOtp = this.state.formOtp;

        if (formOtp[i] !== "" && value !== "")
            return;

        formOtp[i] = value;
        this.setState({formOtp: formOtp}, () => {
            if (i === 3) {
                this.onCheckOtp()
            }
        });

        if (i < 3) {
            document.getElementById('auth-input-' + (i + 2)).focus();
        }
    }

    onCheckOtp() {
        let formOtp = this.state.formOtp.join("");
        let error = false;

        if (formOtp === "")
            error = "Il codice non può essere vuoto";

        this.setState({error: error});

        if (!error) {
            this.setState({loading: true});
            AuthAPI.verifyOTP(formOtp, this.props.token.string).then((user) => {
                window.location.replace('/');
            }).catch((error) => {
                this.setState({
                    loading: false,
                    error: (error === 'SERVER_ERROR' || error === 'APP_ERROR') ? 'Si è verificato un errore di connessione' : 'Il codice non è stato riconosciuto',
                    formOtp: ["", "", "", ""]
                });
            })
        }
    }

    resendOTP() {
        if ( Number(moment().format("X")) > this.state.lastSend+60) {
            AuthAPI.resendOTP(this.props.token.string).then(() => {
                this.setState({lastSend: Number(moment().format("X"))});
                global.SweetAlert.fire({
                    title: 'Nuovo codice OTP richiesto',
                    icon: 'success',
                    allowOutsideClick: false,
                    timer: 1500,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'OK'
                });
            }).catch((error) => {
                if (error.status === "INVALID_TOKEN")
                    window.location.replace('/login');
                this.setState({
                    error: 'Si è verificato un errore',
                });
            })
        } else {
            this.setState({error: "Puoi richiedere un nuovo codice tra " + ((this.state.lastSend+60) - Number(moment().format("X"))) + " secondi"});
        }
    }


    render() {
        document.title = "Verifica OTP | Escort Project";

        return <React.Fragment>
                    <div className="text-center mt-2">
                        <h4 className="text-primary">Autenticazione richiesta 💬</h4>
                        <h5 className="text-primary">Ti abbiamo inviato un codice via SMS al numero <br /><strong>{this.props.token.cell}</strong></h5>
                    </div>
                    <div className="p-2 mt-4">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.onSignupPress()
                            }}
                            action="#">

                            <Row>
                                <Col className="col-3">
                                    <div className="mb-3">
                                        <Input type="number" tabIndex={1} onChange={(text) => this.onChangeText(0, text.target.value)} value={this.state.formOtp[0]} autoFocus id="auth-input-1" className="form-control form-control-lg bg-light border-light text-center" />
                                    </div>
                                </Col>

                                <Col className="col-3">
                                    <div className="mb-3">
                                        <Input type="number" tabIndex={2} onChange={(text) => this.onChangeText(1, text.target.value)} value={this.state.formOtp[1]} id="auth-input-2" className="form-control form-control-lg bg-light border-light text-center" />
                                    </div>
                                </Col>

                                <Col className="col-3">
                                    <div className="mb-3">
                                        <Input type="number" tabIndex={3} onChange={(text) => this.onChangeText(2, text.target.value)} value={this.state.formOtp[2]} id="auth-input-3" className="form-control form-control-lg bg-light border-light text-center" />
                                    </div>
                                </Col>

                                <Col className="col-3">
                                    <div className="mb-3">
                                        <Input type="number" tabIndex={4} onChange={(text) => this.onChangeText(3, text.target.value)} value={this.state.formOtp[3]} id="auth-input-4" className="form-control form-control-lg bg-light border-light text-center" />
                                    </div>
                                </Col>
                            </Row>

                            {(this.state.error) ? <Alert color='danger'>
                                    {(Boolean(this.state.error)) ? this.state.error : null}
                            </Alert> : null}

                            <div className="mt-4">
                                <Button tabIndex={5} color="success" className="btn btn-success w-100" type="submit">Verifica codice {(this.state.loading ? <Spinner size='sm' type='grow' /> : null)}</Button>
                            </div>
                        </Form>
                        <div className="mt-4 text-center">
                            <p className="mb-0">Non hai ricevuto il codice? <Link onClick={() => this.resendOTP()} className="fw-semibold text-primary text-decoration-underline">Reinvia SMS</Link></p>
                        </div>
                    </div>

        </React.Fragment>
    }
}

export default RedirectSignupForm;
