
let get = function(startDate, endDate) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.dashboard + "?start_date=" + startDate + "&end_date=" + endDate, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('get dashboard', responseJson);
                if (responseJson.status === "DASHBOARD")
                    resolve(responseJson.dashboard);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


const DashboardAPI = {
    get: get
};

module.exports = DashboardAPI;
