let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.promoter, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                // global.checkAuth(responseJson.status);
                console.log('promoter list', responseJson);
                if (responseJson.status === "LIST_PROMOTERS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.promoter + "/" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('promoter detail', responseJson);
                if (responseJson.status === "PROMOTER_DETAIL_OK")
                    resolve(responseJson.promoter);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(promoter) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.promoter, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: promoter.id,
                // firstname: promoter.firstname,
                // lastname: promoter.lastname,
                // vat_id: promoter.vat_id,
                // cell: promoter.cell,
                // email: promoter.email,
                // country: promoter.country,
                // fiscal_code: promoter.fiscal_code,
                // full_address: promoter.full_address,
                // job: promoter.job,
                // shift: promoter.shift,
                // details: promoter.details,
                // active: promoter.active
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('promoter edit', responseJson);
                if (responseJson.status === "PROMOTER_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.promoter, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('promoter delete', responseJson);
                if (responseJson.status === "PROMOTER_DELETED")
                    resolve(responseJson.promoter);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const PromoterAPI = {
    list: list,
    detail: detail,
    edit: edit,
    remove: remove
};

module.exports = PromoterAPI;
