import React, { Component } from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, NavLink, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {Loading, Utils} from "custom";
import {AuthAPI, PromoterAPI, UserAPI} from "api";


class PromoterTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            promoters: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getPromoters();
    }

    getPromoters() {
        PromoterAPI.list().then((promoters) => {
            this.setState({loading: false, error: false, promoters: promoters});
        }).catch((err) => {
            console.error(err)
            this.setState({error: true});
        })
    }

    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista promoters.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <React.Fragment>
                    <Grid
                    data={this.state.promoters}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'country',
                            formatter: (cell, row) => _(<span className="text-nowrap"><div className="text-center"><img src={Utils.getFlagByCountry(cell)} width="25"/></div></span>)
                        },
                        {
                            id: 'name',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap"><a href="javascript:void(0)" onClick={() => self.props.history.push("/promoter/" + row.cells[0].data)}><b>{cell}</b></a></span>
                            ),
                            name: "Nome"
                        },
                        {
                            id: 'location',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            name: "Località"
                        },
                        {
                            id: 'cell',
                            name: "Cellulare",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <a href={"tel:" + cell}>{cell.slice(0, 3) + " " + cell.slice(3) }</a>
                            </div>)
                        },
                        {
                            id: 'email',
                            name: "Email",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <a href={"mailto:" + cell} target="_blank">{cell}</a>
                            </div>)
                        },
                        {
                            id: 'status',
                            name: 'Stato',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(cell === null) ? <Badge color="success" >Attivo</Badge> : ((cell === "PRESIGNUP") ? <Badge color="primary" >Pre Registrazione</Badge> : <Badge color="warning">In Attesa</Badge>)}
                            </span>)
                        },
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    sort={true}
                    search={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Promoters',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun promoter trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                /></React.Fragment>}
            </React.Fragment>
        );
    }
}

export default withRouter(PromoterTable);
