import _Loading from './Loading';
import _Utils from './Utils';
import _CustomModal from './CustomModal';
import _GeoForm from './GeoForm';

import _GestAdmin from "./Components/AdminAction/GestAdmin";
import _DeleteAdmin from "./Components/AdminAction/DeleteAdmin";

// import _ListSell from "./Components/SellAction/ListSell";
// import _GestSell from "./Components/SellAction/GestSell";
// import _DeleteSell from "./Components/SellAction/DeleteSell";





export const Loading = _Loading;
export const CustomModal = _CustomModal;
export const Utils = _Utils;
export const GeoForm = _GeoForm;

export const GestAdmin = _GestAdmin;
export const DeleteAdmin = _DeleteAdmin;


// export const ListSell = _ListSell;
// export const GestSell = _GestSell;
// export const DeleteSell = _DeleteSell;
