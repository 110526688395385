import React from "react";
import {Link, Redirect} from "react-router-dom";

import Unauthorized from "../pages/Errors/Unauthorized";
import Offlinepage from "../pages/Errors/Offlinepage";
import Error500 from "../pages/Errors/Error500";

import RedirectPage from "../pages/Authentication/RedirectPage";

import Login from "../pages/Authentication/Login";
// import Dashboard from "../pages/Dashboard/Dashboard";

import { Utils } from "custom";

import AdministratorList from "../pages/Administrator/AdministratorList";

import PromoterList from "../pages/Promoter/PromoterList";

import LocationList from "../pages/Location/LocationList";


// import ClientList from "../pages/Client/ClientList";
// import ClientGest from "../pages/Client/ClientGest";
// import ClientDetail from "../pages/Client/ClientDetail";
//
//
// import SellList from "../pages/Sell/SellList";
// import SellCreate from "../pages/Sell/SellCreate";
// import SellGest from "../pages/Sell/SellGest";



const authProtectedRoutes = [
  // { path: "/dashboard", auth: ["ADMIN", "CONSULENT"], component: Dashboard },

  { path: "/admin", auth: ["ADMIN"], component: AdministratorList },
  { path: "/promoter", auth: ["ADMIN"], component: PromoterList },
  { path: "/location", auth: ["ADMIN"], component: LocationList },


  // { path: "/client", auth: ["ADMIN", "CONSULENT"], component: ClientList },
  // { path: "/client/add", auth: ["ADMIN", "CONSULENT"], component: ClientGest },
  // { path: "/client/edit/:id", auth: ["ADMIN", "CONSULENT"], component: ClientGest },
  // { path: "/client/:id", auth: ["ADMIN", "CONSULENT"], component: ClientDetail },


  // { path: "/sell", auth: ["ADMIN", "CONSULENT"], component: SellList },
  // { path: "/sell/:id", auth: ["ADMIN", "CONSULENT"], component: SellGest },
  // { path: "/sell/create", auth: ["ADMIN", "CONSULENT"], component: SellCreate },
  {
    path: "/",
    auth: ["ADMIN", "TEACHER", "CONSULENT", "CLIENT"],
    exact: true,
    component: () => <Redirect to={"/promoter"} />,
  }
];

const publicRoutes = [
  { path: "/redirect", component: RedirectPage },
  { path: "/login", component: Login },
  { path: "/offline", component: Offlinepage },
  { path: "/unauthorized", component: Unauthorized },
  { path: "/server/error", component: Error500 }
];

export { authProtectedRoutes, publicRoutes };
