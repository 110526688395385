import _GeoAPI from './GeoAPI';
import _AuthAPI from './AuthAPI';
import _UserAPI from './UserAPI';
import _AdminAPI from './AdminAPI';
import _PromoterAPI from './PromoterAPI';
import _LocationAPI from './LocationAPI';


import _ClientAPI from './ClientAPI';
import _SellAPI from './SellAPI';
import _StreamingAPI from './StreamingAPI';
import _DashboardAPI from './DashboardAPI';


export const GeoAPI = _GeoAPI;
export const AuthAPI = _AuthAPI;
export const UserAPI = _UserAPI;
export const AdminAPI = _AdminAPI;

export const PromoterAPI = _PromoterAPI;

export const LocationAPI = _LocationAPI;


export const ClientAPI = _ClientAPI;
export const SellAPI = _SellAPI;
export const StreamingAPI = _StreamingAPI;
export const DashboardAPI = _DashboardAPI;


