import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import PromoterTable from "./PromoterTable";

import { Utils } from "custom";

class PromoterList extends Component {

    constructor(props) {
        super(props);
        document.title ="Lista Promoter | " + process.env.REACT_APP_NAME;
    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Promoters" pageTitle="Escort Project"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <PromoterTable />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(PromoterList);
