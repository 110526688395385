import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";

import {withRouter, Link, Redirect} from "react-router-dom";

import logoLight from "../../assets/images/logo-light.png";

import { AuthAPI } from "api";

import {Utils, Loading} from "custom";
import RedirectForgotPasswordForm from "./RedirectForgotPasswordForm";
import RedirectOTP from "./RedirectOTP";
import RedirectSignupForm from "./RedirectSignupForm";


class RedirectPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: null,
            token: null,
            loading: true
        };
    }

    componentDidMount() {
        this.checkToken();
    }

    checkToken() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get("token");
        if (token !== null) {
            AuthAPI.checkToken(token).then((res) => {
                sessionStorage.removeItem("userData");
                localStorage.removeItem('hash');
                res.string = token;
                this.setState({loading: false, token: res});
            }).catch(err => {
                global.SweetAlert.fire({
                    title: 'Link non valido',
                    text: 'Il link non è corretto oppure è scaduto',
                    icon: 'warning',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    showCancelButton: false,
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        window.location.replace('/');
                    }
                })
            })
        } else {
            window.location.replace('/login');
        }
    }

    render() {
        return <React.Fragment>
            {(this.state.loading) ? <Loading /> : (<ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="180"/>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        {(this.state.token.type === "signup") ? <RedirectSignupForm token={this.state.token} /> : null}
                                        {(this.state.token.type === "forgotpsw") ? <RedirectForgotPasswordForm token={this.state.token} /> : null}
                                        {(this.state.token.type === "otpcheck") ? <RedirectOTP token={this.state.token} /> : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>)}
        </React.Fragment>
    }
}

export default withRouter(RedirectPage);
