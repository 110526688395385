import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Utils } from "custom";

const Navdata = () => {
    let role = Utils.getUserData().role;

    let menuItems = [];

    switch (role) {
        case "ADMIN":
            menuItems = [
                {
                    label: "Anagrafica",
                    isHeader: true,
                },
                {
                    id: "administrators",
                    label: "Amministratori",
                    icon: "mdi mdi-key",
                    link: "/admin",
                    disabled: false
                },
                {
                    id: "promoters",
                    label: "Promoters",
                    icon: "mdi mdi-gender-female",
                    link: "/promoter",
                    disabled: false
                },
                {
                    id: "clients",
                    label: "Clienti",
                    icon: "mdi mdi-account-group",
                    link: "/client",
                    disabled: true
                },
                {
                    label: "Risultati",
                    isHeader: true,
                },
                {
                    id: "locations",
                    label: "Località",
                    icon: "mdi mdi-map-marker-radius",
                    link: "/location",
                    disabled: false
                },
                {
                    id: "sells",
                    label: "Vendite",
                    icon: "mdi mdi-currency-eur",
                    link: "/sell",
                    disabled: true
                }
            ];
            break;
        default:
            menuItems = [];
            break;
    }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
