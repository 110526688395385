let completeSignup = function (user, token) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.signup + '?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                cell: user.cell,
                password: user.password
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : console.log(response))
            .then((responseJson) => {
                if (responseJson.status === "USER_SIGNUP_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                window.location.assign('/server/error');
            });
    });
};


let resendSignup = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.resendSignup, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('user resendSignup', responseJson);
                if (responseJson.status === "EMAIL_SENT")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const UserAPI = {
    resendSignup: resendSignup,
    completeSignup: completeSignup
};

module.exports = UserAPI;
